import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import FirebaseAuthProvider from 'containers/FirebaseAuthContext';
import WarningContextProvider from 'containers/WarningContext';
import StripeProvider from 'containers/StripeProvider';
import ApplicationConfigProvider from 'containers/ApplicationConfigContext';
import ErrorBoundary from 'components/ErrorBoundary';
import SmallScreenFallback from 'components/SmallScreenFallback/lazy';
import GlobalStyle from 'theme/globalStyle';
import theme from 'theme';
import Helmet from 'components/Helmet';
import client from 'lib/api/apollo';

import Router from '../Router';
import { FeatureFlagProvider } from '../../lib/featureFlags';

const App = () => (
  <BrowserRouter>
    <Helmet />
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <GlobalStyle />
        <SmallScreenFallback>
          <StripeProvider>
            <ApolloProvider client={client}>
              <ApplicationConfigProvider>
                <FirebaseAuthProvider>
                  <FeatureFlagProvider>
                    <WarningContextProvider>
                      <Router />
                    </WarningContextProvider>
                  </FeatureFlagProvider>
                </FirebaseAuthProvider>
              </ApplicationConfigProvider>
            </ApolloProvider>
          </StripeProvider>
        </SmallScreenFallback>
      </ErrorBoundary>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
