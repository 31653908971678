import { useRestClient } from './useRestClient';
import { useCallback, useState } from 'react';
import { createBlobFromResponse, downloadBlob } from 'utils/blob';

export type MediaSliceState =
  | 'IDLE' // Initial state, no action taken yet
  | 'PREPARING' // Preparing to start the download (e.g., API request sent)
  | 'DOWNLOADING' // Actively downloading the media
  | 'PROCESSING' // Embedding or preparing the media for download
  | 'COMPLETED' // Download complete and finalized
  | 'FAILED'; // An error occurred during any step

type MetaType = {
  state: MediaSliceState;
  progress: number;
  error?: string | null;
};

const useMediaSlice = (
  mediaSliceInput: Parameters<ReturnType<typeof useRestClient>[0]['mediaSlice']>[0]
): [downloadAction: (fileName: string) => Promise<void>, meta: MetaType] => {
  const [client] = useRestClient();

  const [state, setState] = useState<MediaSliceState>('IDLE');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const fetchBlobAndDownload = useCallback(
    async (fileName: string) => {
      try {
        setState('PREPARING');
        setProgress(0);
        setError(null);

        const response = await client.mediaSlice(mediaSliceInput, {
          onDownloadProgress(progressEvent) {
            setState('DOWNLOADING');
            const total = progressEvent.total || 0; // Handle cases where total is unknown
            if (total) {
              const percentCompleted = Math.round((progressEvent.loaded / total) * 100);
              setProgress(percentCompleted);
            }
          },
        });

        setState('PROCESSING');
        const blob = await createBlobFromResponse(response);

        downloadBlob(blob, fileName);
        setState('COMPLETED');
      } catch (e: any) {
        setState('FAILED');
        setError(e.toString());
        console.error(e);
      }
    },
    [client, mediaSliceInput]
  );

  const meta = {
    state,
    progress,
    error,
  };

  return [fetchBlobAndDownload, meta];
};

export default useMediaSlice;
