import { getEnvVar } from 'utils/env';
import {
  StoryvineRestApiOperation,
  StoryvineRestApiOperationInput,
  StoryvineRestApiOperationOutput,
} from './types/api';

import { restAuthMiddleware } from './auth';
import { merge } from 'lodash';
import { GenericRestClient } from './GenericClient';

export class StoryvineRestClient extends GenericRestClient<
  StoryvineRestApiOperation,
  StoryvineRestApiOperationInput,
  StoryvineRestApiOperationOutput
> {
  static API_URL_BASE = getEnvVar('REACT_APP_REST_API');

  constructor() {
    super({
      baseURL: StoryvineRestClient.API_URL_BASE,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Interceptor to apply the resolved request context (auth and platform headers in this case)
    this.client.interceptors.request.use(
      async (config) => {
        const resolvedRequestContext = await restAuthMiddleware(config);
        return merge(config, resolvedRequestContext);
      },
      (error) => Promise.reject(error)
    );
  }

  mediaSlice = this.createRequest('media-slice', 'post', {
    responseType: 'arraybuffer',
  });
}
