import axios, {
  type CreateAxiosDefaults,
  type Axios,
  type AxiosError,
  type AxiosRequestConfig,
} from 'axios';

export class GenericRestClient<
  TOp extends string,
  TInput extends Record<TOp, any>,
  TResult extends Record<TOp, any>
> {
  protected client: Axios;

  constructor(clientDefaults: CreateAxiosDefaults = {}) {
    this.client = axios.create(clientDefaults);
  }

  private async request<TReq, TRes>(
    operation: TOp,
    params: TReq,
    methodKey: 'post' | 'get' | 'delete' | 'put' = 'post',
    config?: AxiosRequestConfig
  ) {
    const methodHandler = this.client[methodKey];
    return methodHandler<TRes>(operation, params, config)
      .then((res) => res.data)
      .catch((e: AxiosError) => {
        throw new Error(e.message || 'Unknown error');
      });
  }

  protected createRequest(
    operation: TOp,
    methodKey: 'post' | 'get' | 'delete' | 'put' = 'post',
    axiosConfig?: AxiosRequestConfig
  ) {
    return (params: TInput[TOp], additionalAxiosConfig?: AxiosRequestConfig) =>
      this.request<TOp, TResult[TOp]>(operation, params, methodKey, {
        ...axiosConfig,
        ...additionalAxiosConfig,
      });
  }
}
