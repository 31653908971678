import { currentUser, getIdToken } from 'lib/firebase';

const HEADER_KEYS = {
  PLATFORM: 'x-platform',
};

enum Headers {
  Platform = 'desktop',
}

type CreateAuthMiddlewareParamsType = {
  onError?: (e: unknown) => Promise<void> | void;
};

// The generic here is just the bare minimum, so it supports both axios and apollo-link realms
export const createAuthMiddleware =
  <T extends { headers: Record<string, any> }>(params: CreateAuthMiddlewareParamsType) =>
  async ({ headers }: T) => {
    if (currentUser()) {
      try {
        // Gets cached token, if expired tries to get a new one from Google
        // In the event we have signed out the user forcefully it will throw an error
        const token = await getIdToken();

        return {
          headers: {
            ...headers,
            [HEADER_KEYS.PLATFORM]: Headers.Platform,
            authorization: `Bearer ${token}`,
          },
        };
      } catch (e) {
        if (params.onError) {
          params.onError(e);
        }
      }
    }
  };
