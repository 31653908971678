import { setContext } from '@apollo/client/link/context';
import { FirebaseError } from 'firebase/app';
import { signOut } from 'lib/firebase';
import Notification from '../../../components/Notification';
import { createAuthMiddleware } from '../shared';

import apolloClient from './index';

const authMiddleware = createAuthMiddleware({
  async onError(e) {
    if (e instanceof FirebaseError && e.code === 'auth/user-token-expired') {
      await apolloClient.stop();
      await signOut();
      await apolloClient.clearStore();
      // The future versions (4.10+) of Antd supports onClick handler, that could close the message on click, but we are stuck because of typescript
      // And I don't feel like going down the rabbit hole of updating it
      // Since the logout requires a user interaction it should be ok to set the duration to 10 seconds
      // I can't also easily set the notification on the login screen because this is happening outside react
      Notification.error(
        'Your session was forcefully terminated or expired, your are now logged out.',
        10
      );
    }
  },
});

export const authLink = setContext(async (_, ctx) => {
  return authMiddleware(ctx);
});
