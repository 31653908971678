export const downloadBlob = (blob: Blob, fileName: string, mimeType?: string) => {
  const url = window.URL.createObjectURL(new Blob([blob], { type: mimeType || blob.type }));
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(url); // Clean up memory
};

export const createBlobFromResponse = async (response: any, mimeType?: string): Promise<Blob> => {
  // Check if the response is already a Blob (some libraries might handle this)
  if (response instanceof Blob) {
    return response;
  }

  // Check if the response has a blob method (e.g., fetch API)
  if (response && response.blob) {
    return await response.blob();
  }

  // Fallback: Attempt to convert the response data to a Blob
  if (response && response.data) {
    // Axios and similar libraries
    return new Blob([response.data], { type: mimeType });
  } else if (response) {
    // Directly array buffer of Uint8array
    return new Blob([response], { type: mimeType });
  }

  throw new Error('Invalid response format. Unable to create Blob.');
};

export const createBlobFromString = async (data: string) => {
  return new Blob([data]);
};
