import { FirebaseError } from 'firebase/app';
import { signOut } from 'lib/firebase';
import Notification from '../../../components/Notification';
import { createAuthMiddleware } from '../shared';

export const restAuthMiddleware = createAuthMiddleware({
  async onError(e) {
    if (e instanceof FirebaseError && e.code === 'auth/user-token-expired') {
      await signOut();
      Notification.error(
        'Your session was forcefully terminated or expired, your are now logged out.',
        10
      );
    }
  },
});
